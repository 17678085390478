<template>
<div class="col-md-8 mx-auto">
    <div class="card rounded shadow-lg">
      <div class="card-body">
        <div class="card-title text-center">
          <h1>{{ title }}</h1>
        </div>
        <br>
        <form v-on:submit.prevent="saveItem()">
          <div class="form-group">
            <label>Compañía (*):</label>
            <select v-model="item.cmp_id" class="form-control" required autofocus v-bind:disabled="!add">
              <option v-for="compania in companias" v-bind:value="compania._id">
                {{ compania.cmp_nmbre }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label>Usuario (*):</label>
            <input type="text" ref="usu_cdgo" v-on:keyup="validarUsuario" pattern="^[a-z]+[a-z0-9_\-.@]+$" class="form-control" v-model="item.usu_cdgo" required>
            <p v-if="usu_cdgoMsg" class="alert alert-warning" role="alert">{{ usu_cdgoMsg }}</p>
          </div>
          <div class="form-group">
            <label>Nombre (*):</label>
            <input type="text" class="form-control" v-model="item.usu_nmbre" required>
          </div>
          <div class="form-group">
            <label>Apellido (*):</label>
            <input type="text" class="form-control" v-model="item.usu_aplldo" required>
          </div>
          <div class="form-group">
            <label>Número de identificación:</label>
            <input type="number" class="form-control" v-model="item.usu_identificacion">
          </div>
          <div class="form-group">
            <label>Correo electrónico:</label>
            <input type="email" class="form-control" v-model="item.usu_email">
          </div>
          <div class="form-group">
            <label>Tipo de usuario (*):</label>
            <select v-model="item.tus_id" class="form-control" required>
              <option v-for="tpousu in tpousus" v-bind:value="tpousu._id">
                {{ tpousu.tus_nmbre }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label>Fecha de registro:</label>
            <input type="text" class="form-control" v-model="fechareg" disabled>
          </div>
          <div class="form-row">
            <div class="form-group col-md-7">
              <label>Contraseña (*):</label>
              <input v-if="add" type="password" class="form-control" v-model="item.usu_pwd" required>
              <button v-if="!add" type="button" class="btn btn-secondary form-control" v-on:click.prevent="newPwd()" data-toggle="modal" data-target="#pwdModal">Cambiar contraseña</button>
              <pwdchange v-on:change-pwd="pwdChange(pwd)" v-bind:item="pwd"></pwdchange>              
            </div>
            <div class="form-group col-md-5">
              <input type="checkbox" class="form-checkbox-input" id="usu_inactivo" v-model="item.usu_inactivo">
              <label for="usu_inactivo">Inactivo:</label>
            </div>
          </div>
          <div class="container border">
            <h2 class="text-blue">Permisos para documentos</h2>
            <div class="form-group">
              <input type="checkbox" class="form-checkbox-input" id="usu_ver_docs" v-model="item.usu_ver_docs">
              <label for="usu_ver_docs">Ver todos los documentos:</label>
            </div>
            <div class="form-group">
              <input type="checkbox" class="form-checkbox-input" id="usu_modificar_docs" v-model="item.usu_modificar_docs">
              <label for="usu_modificar_docs">Modificar todos los documentos:</label>
            </div>
            <div class="form-group">
              <input type="checkbox" class="form-checkbox-input" id="usu_ver_salas" v-model="item.usu_ver_salas">
              <label for="usu_ver_salas">Ver todas las salas:</label>
            </div>
            <div class="form-group">
              <input type="checkbox" class="form-checkbox-input" id="usu_modificar_salas" v-model="item.usu_modificar_salas">
              <label for="usu_modificar_salas">Modificar todas las salas:</label>
            </div>
          </div>
          <br />
          <div v-if="message">
            <p class="alert alert-warning" role="alert">{{ message }}</p>
          </div>
          <div class="row" align="center">
            <div class="col-4">
              <button type="submit" class="btn btn-primary" v-on:click="setClose(true)">{{ buttontext }}</button>
            </div>
            <div class="col-4">
              <button type="text" class="btn btn-success" v-on:click="setClose(false)">Guardar</button>
            </div>
            <div class="col-4">
              <button type="text" class="btn btn-secondary" v-on:click.prevent="cancel()">Cancelar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  //import toastr from 'toastr';
  import PwdChange from './PwdChange.vue';
  import moment from 'moment';

  export default {
    components: {
      'pwdchange': PwdChange
    },
    data(){
      return{
        add: true, //Create a new document
        close: true, // Close the page and return to list
        title: '',
        item: {},
        message: '',
        buttontext: '',
        tpousus: [],
        companias: [],
        pwd: {},
        usu_cdgoMsg: '',
        fechareg: null
      }
    },
    computed: {
      company() {
        return this.$store.state.company
      },
      user() {
        return this.$store.state.user
      }
    },
    created: function() {
      this.search = this.$route.params.search;
      this.fetchCompania();
      this.fetchTpousu();

      this.add = this.$route.params.add;
      if (this.add){
        this.title = 'Crear un Usuario Global';
        this.buttontext = 'Agregar Usuario Global';
      } else {
        this.title = 'Editar un Usuario Global';
        this.buttontext = 'Actualizar Usuario Global';
        this.getItem(this.$route.params.id);
      }
    },
    methods: {
      validarUsuario(e){
        var t = e.target;
        var pos = t.selectionStart;
        t.value = t.value.toLowerCase();
        t.setSelectionRange(pos, pos);

        var patron =/^[a-z]+[a-z0-9_\-.@]+$/; // Acepta números y letras _-.@
        var valido = patron.test(t.value);
        if (!valido){
          this.$refs.usu_cdgo.style.backgroundColor = '#fff3cd';
          this.usu_cdgoMsg = 'No se permiten caracteres especiales, use letras, números o los símbolos _-.@';
        } else {
          this.$refs.usu_cdgo.style.backgroundColor = '';
          this.usu_cdgoMsg = '';
        }
      },
      setClose(close){
        this.close = close;
      },
      fetchTpousu()
      {
        let uri = '/tpousus';
        this.axios.get(uri).then((response) => {
          this.tpousus = response.data;
        });
      },
      fetchCompania()
      {
        let uri = '/cmps';
        this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
        this.axios.get(uri).then((response) => {
          this.companias = response.data;
        });
      },
      saveItem(){
        if (this.item.usu_pwd.length < 8){
          alert('Advertencia. La contraseña debe tener una longitud mínima de 8 caracteres.');
          return;
        }

        this.item.usu_cdgo = this.item.usu_cdgo.toLowerCase();

        if (this.add){
          let uri = '/usuarios/add';
          //alert(this.item);
          //this.item.usu_cdgo = this.item.usu_cdgo.toLowerCase();
          this.axios.post(uri, this.item)
          .then(response => {
            console.log(response);
            this.$router.replace({ name: 'DisplayUsuarioGlobal', params: { search: this.search } });
            this.message = 'Registro guardado';
          })
          .catch(err => {
            console.log(err);
            this.message = '¡Error al crear el registro! ' + err;
          });
        }
        else {
          let uri = '/usuarios/update/' + this.item._id;
          this.axios.post(uri, this.item).then((response) => {
            if (this.close){
              this.$router.push({ name: 'DisplayUsuarioGlobal', params: { search: this.search } });
            }
            this.message = 'Registro guardado';
          });
        }
      },
      getItem(id)
      {
        let uri = '/usuarios/edit/' + id;
        this.axios.get(uri).then((response) => {
          this.item = response.data;
          if (this.item.usu_fechareg){
            this.fechareg = moment(this.item.usu_fechareg).format('DD/MMM/YYYY hh:mm A');
          }          
        });
      },
      newPwd(){
        this.pwd = { usu_nmbre: this.item.usu_nmbre, usu_aplldo: this.item.usu_aplldo, usu_cdgo: this.item.usu_cdgo }
      },
      pwdChange(p){
        if (p.usu_pwd_new.length < 8){
          alert('Advertencia. La contraseña debe tener una longitud mínima de 8 caracteres.');
          return;
        }
        if (p.usu_pwd_new != p.usu_pwd_conf){
          alert('No se ha confirmado la nueva contraseña. Verifique los datos y vuelva a intentarlo.')
          return;
        }
        let uri = '/usuarios/securityadmin';
        this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
        this.axios.post(uri, { _id: this.item._id, usu_pwd_new: p.usu_pwd_new }).then((response) => {
          alert('La contraseña fue cambiada satisfactoriamente.');
          //this.$router.replace({ name: 'Profile' });
        }).catch((err) => {
          alert('No se ha podido cambiar la contraseña. Verifique los datos y vuelva a intentarlo.');
        });
      },
      cancel(){
        this.$router.replace({ name: 'DisplayUsuarioGlobal', params: { search: this.search } });
      }      
    } // END METHODS
  }
  </script>

  <style>
    .warning {
      color: #ff0000;
    }
  </style>